import { useContext, useEffect, useMemo, useState } from 'react';
import { OfferContext } from '../context';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  IconButton,
  Divider,
  Box,
  Typography,
  Alert,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { showMessage } from '../../../components/utils/showMessage';

export default function BulkEditOffers({
  open,
  handleClose,
  filteredOffers,
  onEditBulkOffer,
}) {
  const [selectedFile, setSelectedFile] = useState(null);
  const [uploadedData, setUploadedData] = useState([]);
  const [showError, setShowError] = useState(false);
  const [invalidEntryDomain, setInvalidEntryDomain] = useState([]);
  const [invalidRedirectLink, setInvalidRedirectLink] = useState([]);
  const [invalidTrafficSource, setInvalidTrafficSource] = useState([]);
  const [updatedOffers, setUpdatedOffers] = useState([]);

  const [trafficMap, setTrafficMap] = useState([]);
  const { traffic, domains } = useContext(OfferContext);

  useEffect(() => {
    const res = traffic.reduce((acc, item) => {
      let trafficFormattedId = '';
      if (item.name) {
        trafficFormattedId = item?.name?.toLowerCase().replace(/\s+/g, '-');
      }
      acc[trafficFormattedId] = item;
      return acc;
    }, {});
    setTrafficMap(res);
  }, [traffic]);

  const domainMap = useMemo(() => {
    return domains.reduce((acc, item) => {
      acc[item.domainLink] = item;
      return acc;
    }, {});
  }, [domains]);

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);

    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const text = e.target.result;
        const parsedData = parseCSV(text);
        // setUploadedData(parsedData);
        formatLinkToId(parsedData);
      };
      reader.readAsText(file);
    }
  };

  const parseCSV = (csvText) => {
    const lines = csvText
      .split('\n')
      .map((line) => line.trim())
      .filter((line) => line);

    const data = lines.slice(1).map((line) => {
      const values = line.split(',').map((value) => value.replace(/"/g, ''));
      return {
        _id: values[0],
        uniqueId: values[1],
        name: values[2],
        offerLink: values[3],
        redirectLink: values[4],
        entryDomain: values[5],
        trafficSource: values[6],
      };
    });

    return data;
  };
  const handleDownloadCSV = () => {
    if (!filteredOffers || filteredOffers.length === 0) {
      alert('No data available to download!');
      return;
    }

    const headers = [
      'Offer ID',
      'Offer Unique ID',
      'Name',
      'Offer Link',
      'Redirect Link',
      'Entry Domain',
      'Traffic Source',
    ];
    const csvRows = [
      headers.join(','),
      ...filteredOffers.map((offer) =>
        [
          `"${offer._id}"`,
          `"${offer.uniqueId}"`,
          `"${offer.name}"`,
          `"${offer.offerLink}"`,
          `"${offer.redirectLink}"`,
          `"${offer.entryDomain}"`,
          `"${offer.trafficSource}"`,
        ].join(',')
      ),
    ];

    const csvContent = `data:text/csv;charset=utf-8,${csvRows.join('\n')}`;
    const encodedUri = encodeURI(csvContent);
    const link = document.createElement('a');
    link.setAttribute('href', encodedUri);
    link.setAttribute('download', 'bulk_offers.csv');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const formatLinkToId = (csvData) => {
    const result = [];
    const entryDomainFalse = [];
    const redirectLinkFalse = [];
    const trafficSourceError = [];
    csvData.map((e) => {
      let parsedUrl = '';
      let entryDomainId = '';
      let trafficSourceId = '';
      let redirectDomainId = '';
      if (e.entryDomain !== '' && e.entryDomain) {
        parsedUrl = new URL(e.entryDomain);
        const domain = domainMap[parsedUrl.origin];
        if (domain?.isEntry === true) {
          entryDomainId = domainMap[parsedUrl.origin]?._id;
        } else {
          entryDomainFalse.push(e.entryDomain);
        }
      } else {
        entryDomainFalse.push('Invalid Empty Entry Domain');
      }

      if (e.redirectLink !== '' && e.redirectLink) {
        parsedUrl = new URL(e.redirectLink);
        redirectDomainId = domainMap[parsedUrl.origin]?._id;
        if (!redirectDomainId) {
          redirectLinkFalse.push(e.redirectLink);
        }
      } else {
        redirectLinkFalse.push('Invalid Empty Redirect Link ');
      }
      if (e.trafficSource !== '' && e.trafficSource) {
        const trafficFormattedId = e.trafficSource
          .toLowerCase()
          .replace(/\s+/g, '-');
        trafficSourceId = trafficMap[trafficFormattedId]?._id;
        if (!trafficSourceId) {
          trafficSourceError.push(e.trafficSource);
        }
      } else {
        trafficSourceError.push('Invalid Empty Traffic Source');
      }

      const updatedOffer = {
        updateOne: {
          filter: { _id: e._id },
          update: {
            $set: {
              redirectDomainId,
              trafficSourceId,
              entryDomainId,
            },
          },
        },
      };

      result.push(updatedOffer);
      return true;
    });

    setUpdatedOffers(result);
    setInvalidEntryDomain(entryDomainFalse);
    setInvalidRedirectLink(redirectLinkFalse);
    setInvalidTrafficSource(trafficSourceError);
    if (
      entryDomainFalse.length > 0 ||
      redirectLinkFalse.length > 0 ||
      trafficSourceError.length > 0
    ) {
      setShowError(true);
      showMessage({
        message:
          'Error uploading of csv. Please see Bulk Edit Dialog for more details',
        severity: 'error',
      });
    } else {
      setShowError(false);
    }
  };

  const onHandleClick = async () => {
    if (
      invalidEntryDomain.length === 0 &&
      invalidTrafficSource.length === 0 &&
      invalidRedirectLink.length === 0
    ) {
      await onEditBulkOffer(updatedOffers);
      showMessage({
        message: 'Offer is successfully updated',
        severity: 'success',
      });
    }
  };

  const onHandleClose = () => {
    setSelectedFile(null);
    setShowError(false);
    setInvalidEntryDomain([]);
    setInvalidRedirectLink([]);
    setInvalidTrafficSource([]);
    handleClose(true);
  };

  const formatInvalidDomain = (invalidDomain) => {
    return invalidDomain.map((item, index) => (
      <span key={index}>
        {item} <br />
      </span>
    ));
  };

  return (
    <Dialog open={open} onClose={handleClose} fullWidth>
      <DialogTitle>
        Bulk Edit Offers
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <Divider sx={{ mt: 1 }} />
      <DialogContent>
        <Box display="flex" flexDirection="column" gap={2}>
          {showError && (
            <Alert
              severity="error"
              sx={{
                maxHeight: '400px', // Limit height
                overflowY: 'scroll', // Enable scrolling
                '& .MuiAlert-message': {
                  width: '100%', // Ensure full width for the message
                },
              }}
            >
              {invalidEntryDomain.length > 0 && (
                <>
                  <p style={{ width: '100%' }}>
                    The following <strong> Entry Domain </strong> is invalid:
                  </p>
                  {formatInvalidDomain(invalidEntryDomain)}
                </>
              )}
              {invalidRedirectLink.length > 0 && (
                <>
                  <p style={{ width: '100%' }}>
                    The following <strong>Redirect link</strong> is invalid:
                  </p>
                  {formatInvalidDomain(invalidRedirectLink)}
                </>
              )}
              {invalidTrafficSource.length > 0 && (
                <>
                  <p style={{ width: '100%' }}>
                    The following <strong>Traffic Source</strong> is invalid:
                  </p>
                  {formatInvalidDomain(invalidTrafficSource)}
                </>
              )}
            </Alert>
          )}
          <Typography>Download CSV File</Typography>
          <Button
            variant="contained"
            color="primary"
            onClick={handleDownloadCSV}
          >
            Download CSV
          </Button>

          <Typography>Upload Offers to Change</Typography>
          <Button
            variant="contained"
            color={selectedFile ? 'success' : 'inherit'}
            component="label"
          >
            Upload CSV
            <input
              type="file"
              accept=".csv"
              hidden
              onChange={handleFileUpload}
            />
          </Button>

          {selectedFile && (
            <Typography>Selected file: {selectedFile.name}</Typography>
          )}

          {uploadedData.length > 0 && (
            <Box>
              <Typography>Uploaded Data Preview:</Typography>
              {uploadedData.slice(0, 5).map((offer, index) => (
                <Typography key={index}>
                  {offer.name} - {offer.offerLink}
                </Typography>
              ))}
              {uploadedData.length > 5 && <Typography>...and more</Typography>}
            </Box>
          )}
        </Box>
      </DialogContent>
      <Divider sx={{ my: 1 }} />
      <DialogActions>
        <Button fullWidth variant="contained" onClick={onHandleClick}>
          Update Offer
        </Button>
        <Button
          fullWidth
          variant="contained"
          color="secondary"
          onClick={onHandleClose}
        >
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}
